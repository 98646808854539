<template>
  <div>
    <v-simple-table class="table-padding-2-no-top">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center" style="width: 150px">Chọn phương án</th>
            <th class="text-center" style="width: 250px">Nguyên liệu</th>
            <th class="text-center" style="width: 150px">SKU nguyên liệu</th>
            <th class="text-center">Đặc tả</th>
            <th class="text-center" style="width: 100px">Size</th>
            <th class="text-center" style="width: 100px">Định lượng</th>
            <th
              class="text-center"
              style="width: 75px"
              v-if="item.production_status < 4"
            >
              <v-btn
                v-if="item.production_status === 2"
                color="success"
                small
                @click="confirmMarker"
                :disabled="isDisabledBtnOk"
              >
                {{ $t("labels.ok") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center">
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="marker.id_goods_production_requirement_plan"
                :items="planOptions"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="marker.id_material"
                :items="materialOptions"
                outlined
                dense
                hide-details
                single-line
                @change="generateMarker"
              ></v-autocomplete>
            </td>
            <td>
              {{ materialSelected.sku }}
            </td>
            <td>
              {{ materialSelected.description }}
            </td>
            <td>
              {{ materialSelected.size }}
            </td>
            <td>
              {{ materialSelected.quantitative }}
            </td>
            <td v-if="item.production_status < 4">
              <v-btn
                color="primary"
                small
                @click="save"
                :disabled="!marker.id_material"
              >
                {{ $t("labels.save") }}
              </v-btn>
              <input
                type="file"
                ref="inputUploadFile"
                accept="image/*,application/pdf"
                @change="onInputFileChange"
                class="d-none"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table
      class="table-padding-2-no-top table-h-36 mt-5"
      v-for="(mk, mi) in markers"
      :key="`m_${mi}`"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th colspan="12" class="fs-16px primary--text">{{ mk.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="font-weight-medium text-center">
            <td>{{ $t("labels.category") }}</td>
            <td>{{ $t("labels.name") }}</td>
            <td>{{ $t("labels.goods_description") }}</td>
            <td>{{ $t("labels.size") }}</td>
            <td>YCSX</td>
            <td style="width: 110px">SL/ Bàn</td>
            <td>Số lớp</td>
            <td>Số lượng cắt</td>
            <td>YCSX còn</td>
            <td style="width: 110px">Dài bàn (cm)</td>
            <td>01 lớp sử dụng (g)</td>
            <td>File giác</td>
          </tr>
          <tr
            v-for="(val, index) in mk.items"
            :key="`i_${mi}_${val.id}_${index}`"
            class="text-center"
          >
            <td>{{ val.category_name }}</td>
            <td>{{ val.name }}</td>
            <td>{{ val.description }}</td>
            <td>{{ val.size }}</td>
            <td>{{ val.quantity }}</td>
            <td>
              <v-text-field
                v-if="item.production_status < 4"
                class="c-input-small"
                v-model.number="val.quantity_per_layer"
                outlined
                dense
                hide-details
                single-line
                type="number"
                @keyup="onQuantityChange(mi)"
              ></v-text-field>
              <span v-else>{{ val.quantity_per_layer }}</span>
            </td>
            <td v-if="index === 0" :rowspan="mk.items.length">
              {{ mk.layer_quantitty }}
            </td>
            <td>{{ val.cut_quantity }}</td>
            <td>{{ val.remaining_quantity }}</td>
            <td v-if="index === 0" :rowspan="mk.items.length">
              <v-text-field
                v-if="item.production_status < 4"
                class="c-input-small"
                v-model.number="mk.table_width"
                outlined
                dense
                hide-details
                single-line
                type="number"
                @keyup="onTableWidthChange(mi)"
              ></v-text-field>
              <span v-else>{{ mk.table_width }}</span>
            </td>
            <td v-if="index === 0" :rowspan="mk.items.length">
              {{ mk.weight_per_layer }}
            </td>
            <td v-if="index === 0" :rowspan="mk.items.length">
              <span
                v-if="item.production_status < 4"
                class="primary--text font-weight-medium cursor-pointer"
                @click="showUploadFile(mi)"
              >
                {{ $t("labels.upload") }}
                <br />
              </span>
              <span v-if="mk.files && mk.files.length > 0">
                ({{ mk.files.length }})
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="12"></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import { compress } from "image-conversion";
import moment from "moment";

export default {
  name: "RequirementQuantitativeMarker",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    plans: [],
    marker: {
      id_goods_production_requirement_plan: null,
      id_material: null,
    },
    markers: [],
    uploadIndex: null,
  }),
  computed: {
    planOptions() {
      if (!this.plans || this.plans.length === 0) {
        return [];
      }
      return [...this.plans].map((plan) => ({
        text: `Phương án ${plan.name}`,
        value: plan.id,
      }));
    },
    planSelected() {
      return [...this.plans].find(
        (pl) => pl.id === this.marker.id_goods_production_requirement_plan
      );
    },
    planSelectedItems() {
      return (this.planSelected && this.planSelected.items) || [];
    },
    materialOptions() {
      if (
        !this.marker.id_goods_production_requirement_plan ||
        !this.planSelected
      ) {
        return [];
      }
      return [...this.planSelectedItems].map((item) => ({
        text: item.name,
        value: item.id_material,
      }));
    },
    materialSelected() {
      return (
        [...this.planSelectedItems].find(
          (pi) => pi.id_material === this.marker.id_material
        ) || {}
      );
    },
    isDisabledBtnOk() {
      const checkMarker = [...this.markers].filter(
        (mk) =>
          !mk.layer_quantitty ||
          !mk.table_width ||
          !mk.weight_per_layer ||
          !mk.file
      );
      return checkMarker.length > 0;
    },
  },
  created() {},
  mounted() {
    this.getPlanAndGroupMaterial();
  },
  methods: {
    showUploadFile(index) {
      this.uploadIndex = index;
      this.$refs.inputUploadFile.click();
    },

    async confirmMarker() {
      if (
        !confirm(
          this.$t(`messages.goods_production_requirement_confirm`, {
            tracking: this.item.tracking_id,
          })
        )
      ) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-requirement-confirm-marker", {
          id_goods_production_requirement: this.item.id,
        });
        this.isLoading = false;
        const msg = this.$t("messages.update_success");
        this.$vToastify.success(msg);
        this.$emit("confirmMarker", true);
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      let urls = [];
      for (let i = 0; i < files.length; i++) {
        const url = await this.uploadFile(files[i]);
        urls.push(url);
      }
      const markers = [...this.markers];
      markers[this.uploadIndex].files = [...urls];
      markers[this.uploadIndex].file = urls.join(";");
      this.markers = [...markers];
      this.uploadIndex = null;
      this.$refs.inputUploadFile.value = null;
      this.isLoading = false;
    },

    async uploadFile(file) {
      console.log(file);
      let fd = new FormData();
      if (file.type.includes("image")) {
        const resBlob = await compress(file, {
          quality: 0.85,
          type: "image/jpeg",
        });
        const newFile = new File(
          [resBlob],
          `${moment().unix()}_${this.generateRandomString(6)}.jpg`,
          { type: "image/jpeg" }
        );
        fd.append("file", newFile);
      } else {
        fd.append("file", file);
      }
      const { data } = await httpClient.post(`/upload-image`, fd);
      return data;
    },
    getPlanAndGroupMaterial: debounce(function () {
      httpClient
        .post("/goods-production-requirement-get-plan-and-group-material", {
          id_goods_production_requirement: this.item.id,
        })
        .then(({ data }) => {
          this.plans = [...data];
        });
    }, 500),
    onQuantityChange: debounce(function (index) {
      let markers = [...this.markers];
      const markerItems = markers[index].items;

      let layer_quantitty = null;
      for (let i = 0; i < markerItems.length; i++) {
        const markerItem = markerItems[i];
        const lq = Math.floor(
          markerItem.quantity / markerItem.quantity_per_layer
        );
        if (!layer_quantitty) {
          layer_quantitty = lq;
        }
        if (layer_quantitty > lq) {
          layer_quantitty = lq;
        }
      }
      markers[index].layer_quantitty = layer_quantitty;

      for (let i = 0; i < markerItems.length; i++) {
        const markerItem = markerItems[i];
        markerItem.cut_quantity =
          layer_quantitty * markerItem.quantity_per_layer;
        markerItem.remaining_quantity =
          markerItem.quantity - markerItem.cut_quantity;
        markerItems[i] = { ...markerItem };
      }

      markers = [...markers].filter((m, i) => i <= index);
      const checkMarkerEmpty = [...markerItems].filter(
        (marker) => marker.quantity_per_layer === null
      );
      if (checkMarkerEmpty.length === 0) {
        const markerRemaining = [...markerItems].filter(
          (marker) => marker.remaining_quantity > 0
        );
        const items = [];
        for (let i = 0; i < markerRemaining.length; i++) {
          const item = markerRemaining[i];
          items.push({
            ...item,
            quantity: item.remaining_quantity,
            quantity_per_layer: null,
            cut_quantity: null,
            remaining_quantity: null,
          });
        }

        if (items.length > 0) {
          markers.push({
            name: `Bản giác ${this.markers.length + 1}`,
            layer_quantitty: null,
            table_width: null,
            weight_per_layer: null,
            file: null,
            items,
          });
        }
      }

      this.markers = [...markers];
    }, 500),
    onTableWidthChange: debounce(function (mi) {
      const markers = [...this.markers];
      markers[mi].weight_per_layer = Math.ceil(
        (markers[mi].table_width *
          this.materialSelected.size *
          this.materialSelected.quantitative) /
          10000
      );
      this.markers = [...markers];
    }, 500),
    generateMarker() {
      httpClient
        .post("/goods-production-requirement-marker-list", {
          id_goods_production_requirement: this.item.id,
          ...this.marker,
        })
        .then(({ data }) => {
          this.markers = [...data].map((mk) => ({
            ...mk,
            files: (mk.file && mk.file.split(";")) || [],
          }));
          if (!this.markers || this.markers.length === 0) {
            const items = [];
            for (let i = 0; i < this.items.length; i++) {
              const item = this.items[i];
              items.push({
                ...item,
                id: null,
                quantity_per_layer: null,
                cut_quantity: null,
                remaining_quantity: null,
              });
            }

            const markers = [
              {
                name: `Bản giác ${this.markers.length + 1}`,
                layer_quantitty: null,
                table_width: null,
                weight_per_layer: null,
                file: null,
                items,
              },
            ];

            this.markers = [...markers];
          }
        });
    },

    async save() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-requirement-marker-save", {
          ...this.marker,
          id_goods_production_requirement: this.item.id,
          items: this.markers,
        });
        this.isLoading = false;
        const msg = this.$t("messages.update_success");
        this.$vToastify.success(msg);
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
